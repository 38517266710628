// Node Modules
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

// Components
import ArticlesText from '../components/content-sections/statics/ArticlesText';
import BlogListing from '../components/resuables/BlogListing';
import JoinUsFormWrapper from '../components/form/presentational/JoinUsFormWrapper';
import Layout from '../components/core/layout';
import SEO from '../components/core/seo';

// Styles
import { colors, space } from '../style/design-system';

const ArticlesPage = ({ className, data }) => (
  <Layout>
    <SEO title="Articles" />
    <div className={className}>
      <ArticlesText />
      <BlogListing blogPosts={data.allBlogPosts.edges} />
      <JoinUsFormWrapper hideCourses />
    </div>
  </Layout>
);

export default styled(ArticlesPage)`
background-color: ${colors.umaLightGray};
  [class^="ArticlesText"] {
    padding: ${space.g} 0 ${space.d} 0;
  }
`;

export const query = graphql`{
  allBlogPosts: allMarkdownRemark(filter: {frontmatter: { type: {eq: "blogPost"}}}) {
    edges {
      node {
      frontmatter {
          link
          text
          image {
            id
            path {
              childImageSharp {
                gatsbyImageData(quality: 85, width: 400, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
}`;

ArticlesPage.propTypes = {
  className: PropTypes.string.isRequired,
};
